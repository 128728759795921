import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {history} from './history';

import { PrivateRoute } from './PrivateRoute';
import LoginPage from '../components/login/index';
import Office365 from '../components/office365';
import LogoutPage from '../components/login/logout';
import RegisterPage from '../components/register';
import PasswordPage from '../components/passwordReset';
import LeadPage from '../components/lead/listing/loadable';
import LeadDetail from '../components/lead/detail/loadable';
import Privacy from '../components/staticPages/privacy';
import TOC from '../components/staticPages/toc';
import UserPage from '../components/user/loadable';
import ProductPage from '../components/product/listing/loadable';
import ProductDetail from '../components/product/detail/loadable';
import MonitorPage from '../components/monitor/loadable';
import VerifyPage from '../components/staticPages/verify';

class App extends React.Component {
	render() {
		console.log(history, '15865asd1f');
		return (
			<Router history={history}>
				<div className="leadPage">
					<Route path="/Office365ReturnUrl/:code?" component={Office365} />
					<Route path="/login" component={LoginPage} />
					<Route path="/logout" component={LogoutPage} />
					<Route path="/register" component={RegisterPage} />
					<Route path="/password" component={PasswordPage} />
					<Route path="/verify/:type/:code?" component={VerifyPage} />
					<Route path="/privacy" component={Privacy} />
					<Route path="/terms" component={TOC} />

					<PrivateRoute exact path="/" component={LeadPage} />
					<PrivateRoute exact path="/lead" component={LeadPage} />
					<PrivateRoute exact path="/lead/:id/:tab?" component={LeadDetail} />
					<PrivateRoute exact path="/user" component={UserPage} />
					<PrivateRoute exact path="/product" component={ProductPage} />
					<PrivateRoute exact path="/product/:id/:tab?" component={ProductDetail} />
					<PrivateRoute exact path="/monitor" component={MonitorPage} />
				</div>
			</Router>
		);
	}
}

function mapStateToProps(state) {
		const { alert } = state;
		return {
				alert
		};
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as Route };