const initialState = {
  user: JSON.parse(localStorage.getItem('_user')),
  pisDetailLoaded: false,
  tab: 0,
  productdetail: {},
  activities: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'updateValues':
      return {...state, ...action.payload}
    default:
      return state
  }
}