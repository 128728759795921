import React from 'react';
export function showLoading(data = {small: false}){
	var _style = {
		marginTop : data.marginTop ? data.marginTop : 0,
		paddingBottom : data.paddingBottom ? data.paddingBottom : 0
	}
	return(
		<div className="showbox" style={_style}>
			<div className={"loader" + (data.small ? ' small' : ' ')}>
				<svg className="circular" viewBox="25 25 50 50">
					<circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
				</svg>
			</div>
		</div>
	)
}
export function opportunityACL(opportunity, type){
	if(opportunity.isCoOwner){
		if(type === 'move' && opportunity.coOwnerCanMoveOpportunity){
			return true;
		}
		else if(type === 'view' && opportunity.coOwnerCanViewOppDetails){
			return true;
		}
		else if(type === 'edit' && opportunity.coOwnerCanEditOpportunity){
			return true;
		}
		else if(type === 'addActivity' && opportunity.coOwnerCanAddActivity){
			return true;
		}
		else if(type === 'editActivity' && opportunity.coOwnerCanEditActivity){
			return true;
		}
		else if(type === 'deleteActivity' && opportunity.coOwnerCanDeleteActivity){
			return true;
		}
		return false;
	}
	return true;
}
export function getHighlightedText(text, higlight) {
	if(higlight.length <= 0) return text;
	let kws = higlight.split(" ").filter(e=> e !== undefined && e !== '').map(v => `(${v.escapeRegExp()})`).join("|");
	//let reg = '/'+kws+'/gi';
	let parts = text.split(new RegExp(`${kws}`, 'gi'));
	return <span> { parts.filter(el=> el !== undefined && el !== '').map((part, i) =>
		<span key={i} style={new RegExp(`${kws}`, 'gi').test(part.toLowerCase()) ? { color: '#93C74B' } : {} }>
			{ part }
		</span>)
	} </span>;
}
export function numberWithcomma(n=0, d=2){
	var parts = Number(Math.round(n+'e'+d)+'e-'+d).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
	/*return n.toFixed(0).replace(/./g, function(c, i, a) {
		return i && c !== "." && c !== "-" && ((a.length - i) % 3 === 0) ? ',' + c : c;
	})*/
}
export function decimalNumberWithcomma(n){
	if(!n)
		return n;
	return n.toFixed(2).replace(/./g, function(c, i, a) {
		return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
	})
}
export function titleCase(str) {
	if(str){
		str = typeof str ==='string' ? str : str.toString();
		var splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
		}
		return splitStr.join(' '); 
	}
	else{
		return str;
	}
}
export function convertToBase64(file, callback){
	var fileLoader = new FileReader(),
	canvas = document.createElement('canvas'),
	context = null,
	imageObj = new Image(),
	blob = null;
	context = canvas.getContext('2d');
	if (file.type.match('image.*')) {
		fileLoader.readAsDataURL(file);
	}
	fileLoader.onload = function() {
		var data = this.result; 
		imageObj.src = data;
	};
	imageObj.onload = function() {
		canvas.width  = imageObj.width;
		canvas.height = imageObj.height;
		context.clearRect(0, 0, imageObj.width, imageObj.height);
		context.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height);
		blob = canvas.toDataURL('image/jpeg', 1);
		if(callback)
			callback(blob);
	}
}
export function resize(file, max_width, max_height, callback){
	var fileLoader = new FileReader(),
	canvas = document.createElement('canvas'),
	context = null,
	imageObj = new Image(),
	blob = null;
	canvas.width  = max_width;
	canvas.height = max_height;
	context = canvas.getContext('2d');
	if (file.type.match('image.*')) {
		fileLoader.readAsDataURL(file);
	}
	fileLoader.onload = function() {
		var data = this.result; 
		imageObj.src = data;
	};
	imageObj.onload = function() {
		let width=imageObj.width, height=imageObj.height,
			multiplier= width >= height ? (max_width / width) : (max_width / height);
		canvas.width  = parseInt(width * multiplier);
		canvas.height = parseInt(height * multiplier);
		context.clearRect(0,0, parseInt(width * multiplier), parseInt(height * multiplier));
		context.drawImage(imageObj, 0, 0, parseInt(width * multiplier), parseInt(height * multiplier));

		// context.clearRect(0,0,max_width,max_height);
		// context.drawImage(imageObj, 0, 0, max_width, max_height);
		blob = canvas.toDataURL('image/jpeg', 1);
		if(callback)
			callback(blob);
	}
}
export function formatDate(date) {
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct","Nov", "Dec"];

  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + day + ', ' + year;
}
export function contactDynamicListIcon(type){
	let _icon = 'ic_ac_phone';
	if(type==='phone'){
		_icon = 'ic_ac_phone';
	}
	else if(type==='location'){
		_icon = 'ic_add_place';
	}
	else if(type==='email'){
		_icon = 'ic_ac_email2';
	}
	else if(type==='webaddress'){
		_icon = 'ic_ac_web';
	}
	else if(type==='socialmedia'){
		_icon = 'ic_ac_share';
	}
	return _icon;
}
export function deepGet (obj, props, defaultValue) {
	// If we have reached an undefined/null property
	// then stop executing and return the default value.
	// If no default was provided it will be undefined.
	if (obj === undefined || obj === null) {
		return defaultValue;
	}
	// If the path array has no more elements, we've reached
	// the intended property and return its value
	if (props.length === 0) {
		return obj;
	}
	// Prepare our found property and path array for recursion
	var foundSoFar = obj[props[0]];
	var remainingProps = props.slice(1);
	return deepGet(foundSoFar, remainingProps, defaultValue);
}
export function uniqueByProperty (myArr, prop) {
	return myArr.filter((obj, pos, arr) => {
		return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
	});
}
export function UUID(){
	var dt = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		let r = (dt + Math.random()*16)%16 | 0;
		dt = Math.floor(dt/16);
		// eslint-disable-next-line no-mixed-operators
		return (c==='x' ? r :(r&0x3|0x8)).toString(16);
	});
	return uuid;
}
export function arrayToObject(array){
	return array.reduce((obj, item) => {
		obj[item.value] = item.label
		return obj
	}, {})
}
export function toHHMMSS(secs){
	var sec_num = parseInt(secs, 10)    
	var hours   = Math.floor(sec_num / 3600) % 24
	var minutes = Math.floor(sec_num / 60) % 60
	var seconds = sec_num % 60    
	return [hours,minutes,seconds]
			.map(v => v < 10 ? "0" + v : v)
			.filter((v,i) => v !== "00" || i > 0)
			.join(":")
}
export function differenceInMinutes(start=new Date(), end=new Date()) {
  let duration = end.getTime() - start.getTime();
	return ((duration / 1000) / 60);
};
