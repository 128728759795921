import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {styles} from './style';

class LoginPage extends Component {
  constructor(props){
    super();
  }
  componentDidMount(){
    if(localStorage.getItem('_user') || localStorage.getItem('_lg') || localStorage.getItem('_access')){
      localStorage.clear();
      this.props.history.go(0);
    }
    else{
      this.props.history.push('/login')
    }
  }
  render() {
    return (
      <div className="leadsPage">
        <div className="showbox">
          <div className="loader" style={{marginTop: "16px"}}>
            <svg className="circular" viewBox="25 25 50 50">
              <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
          </div>
        </div>
			</div>
    );
  }
}
LoginPage.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({...state})
const mapDispatchToProps = {}
LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default withStyles(styles)(LoginPage);