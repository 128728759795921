import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import NotesIcon from '@material-ui/icons/Notes';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {titleCase, deepGet} from 'components/common/commonFunctions';
import {USER_LOGOUT} from './actions';
import HeaderOptions from './headerOptions';
import {GetData, PostData} from 'store/request';
import uuidv4 from 'uuid/v4';
import Snackbar from '@material-ui/core/Snackbar';
import URL from 'config/urls';

class Header extends Component {
	constructor(props) {
		super();
    this.user = props.user;
    if(!this.user){
      this.user = JSON.parse(localStorage.getItem('_user'))
    }
		this.onDrawarOpen = this.onDrawarOpen.bind(this);
		this.state = {
			open: false,
			snakerOpen: false,
		};
		console.log(this.user);
		this.menuItems = [
			{
				isenable: true,//UserStore.getUserAccessListByModule('lead', 'canRead') ,
				link: '/lead',
				icon: 'lead',
				text: 'leads',
				badge: 0
			},
			{
				isenable: true,//UserStore.getUserAccessListByModule('lead', 'canRead') ,
				link: '/product',
				icon: 'product',
				text: 'product',
				badge: 0
			},
			{
				isenable: deepGet(this.user, ['isAdmin'], false),//UserStore.getUserAccessListByModule('lead', 'canRead') ,
				link: '/user',
				icon: 'user',
				text: 'users',
				badge: 0
			},
			{
				isenable: deepGet(this.user, ['isAdmin'], false),//UserStore.getUserAccessListByModule('lead', 'canRead') ,
				link: '/monitor',
				icon: 'user',
				text: 'monitor',
				badge: 0
			},
		]
	}
	componentDidUpdate(props){
		this.user = props.user;
    if(!this.user){
      this.user = JSON.parse(localStorage.getItem('_user'))
    }
	}
	onDrawarOpen(event){
		this.setState({open: !this.state.open})
	}
	toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
	}
	onSignOut= e=>{
		this.props.USER_LOGOUT();
    this.props.history.push('/logout');
	}
	onLinkClicked = event => {
		event.preventDefault();
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		var target = event.currentTarget;
		var href = target.getAttribute('href');
		if(href){
			this.props.history.push(href);
		}
		return false;
	}
	isJson= (str)=>{
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
	}
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({snakerOpen: false });
  };
  onItemClick= (e, id)=>{
    let {user} = this.props;
    if(e==='office365'){
      GetData('/IsUserThirdPartyIntegrationDone?UserId='+deepGet(user, ['userId'], 0)).then(res => {
        if(!deepGet(res, ['result'], false)){
					let officeURL = 'https://login.windows.net/common/oauth2/authorize?response_type=code&client_id=c600731a-fcb0-4e2b-b842-a8fb92a0bd6f&redirect_uri='+URL.officeWebURL+'/Office365ReturnUrl&resource=https:%2f%2foutlook.office365.com%2f&state='+uuidv4();
					console.log(officeURL);
          window.location.href= officeURL;
				}
				else{
					this.setState({snakerOpen: true});
				}
      });
		}
		else if(e==='removeOffice'){
			PostData('/RemoveThirdPartyIntegration?UserId='+deepGet(user, ['userId'], 0)).then(res => {
				window.location.reload(true);
      });
		}
  }
	render() {
		const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
		let {user, classes} = this.props;
    if(!user){
      user = JSON.parse(localStorage.getItem('_user'))
    }
		const tennantData = this.isJson(localStorage.getItem('tennantData')) ? JSON.parse(localStorage.getItem('tennantData')) : {};
		return (
			<div className={classnames("navigation", {'loggedUser': this.props.isUserLogged})}>
				{!this.props.isUserLogged ? <div className="logo"></div> : 
				<div className="menu">
					<div className="menuBar">
						<NotesIcon className="menuBarIcon" onClick={this.onDrawarOpen} />
					</div>
					<div className="menuTitle">{this.props.title}</div>
				</div>
				}
				{this.props.children}
				{this.props.isUserLogged && 
					<SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS}
						open={this.state.open}
						onClose={this.toggleDrawer('open', false)}
						onOpen={this.toggleDrawer('open', true)}
						PaperProps={{width:"300px"}}
					>
						<div className="drawerWrapper">
							<div className="topContainer">
								<div>
									<div className="drawer dib vt">
										<NotesIcon className="drawerIcon" onClick={this.toggleDrawer('open', false)} />
									</div>
									<div className="headerOptions dib vt">
										<HeaderOptions userId={user.userId} onItemClick={this.onItemClick} />
									</div>
								</div>
								<div className="displayName" ref="userName">{titleCase(user.firstName+' '+user.lastName)}</div>
								<div className="displayEmail">
									<div>{user.email}</div>
									<div className="text-capitalize">{tennantData.tennantName}</div>
								</div>
								<div className="displayImage" ref="profileImage">
									{/* <ProfileImage src={this.state.profilePhoto} /> */}
								</div>
							</div>
							<div className="menu">
								{
									this.menuItems.filter(a=> a.isenable).map((item)=>{
										return(
											<li key={item.text}>
												<a name={classnames({[`menu_${item.text}`]: true, disabled: !item.isenable})} onClick={this.onLinkClicked} href={item.link}>
													<div className="icon"><div className={"menuNavIcon "+item.icon}></div></div>
													<div className="text">{item.text}</div>
													{
														item.badge > 0 && <div name={classnames({[`count_${item.text}`]: true})} className="badge red green yellow">{item.badge}</div>
													}
												</a>
											</li>
										)
									})
								}
							</div>
							<div className="bottomMenu">
								<li onClick={this.onSignOut}>
									<div className="icon"><div className="menuNavIcon signout"></div></div>
									<div className="text">signout</div>
								</li>
							</div>
						</div>
					</SwipeableDrawer>
				}
        <Snackbar
          anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
          classes={{root: classes.error}}
          open={this.state.snakerOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{'aria-describedby': 'message-id'}}
          message={<span id="message-id">Integration already done</span>}
        />
			</div>
		)
	}
}
Header.defaultProps = {
	isUserLogged: false,
	title: '',
	classes: {},
}
Header.propTypes = {
	isUserLogged: PropTypes.bool,
	title: PropTypes.any,
	classes: PropTypes.object,
}
const mapStateToProps = state => ({})
const mapDispatchToProps = {USER_LOGOUT}
export default connect(mapStateToProps, mapDispatchToProps)(Header);