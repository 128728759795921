import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {updateValues} from './actions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FlagIcon from '@material-ui/icons/FlagOutlined';
import {styles} from './style';
import { VisitorPost } from '../../store/request';
import Snackbar from '@material-ui/core/Snackbar';
import {components} from 'react-select';
import AutoComplete from 'components/common/autoComplete';

const countryCodeValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className="dib vt"><FlagIcon className="" /></div>
      <div className="dib vt singleValueText">{children}</div>
    </components.SingleValue>
  );
};

class Step1 extends Component {
  constructor(props){
    super();
    this.onNextClick = this.onNextClick.bind(this);
    this.state = {
      country: props.Register.country,
      number: props.Register.number,
      errorCountry : false,
      errorNumber : false,
      alreadyRegister: false,
      loading: false,
    }
  }
  onNextClick(){
    if(!this.state.country){
      this.setState({errorCountry: true});
    }
    else if(!this.state.number){
      this.setState({errorNumber: true});
    }
    else{
      let country = this.props.Register.countryList.find(m=> m.countryName===this.state.country)
      this.setState({loading: true});
      country && VisitorPost('/GenerateSMSCode', {"PhoneNumber": '+'+country.dialingCode+this.state.number}, data=>{
        let code = data.smsCode.code;
        if(code!==''){
          this.props.onNext({
            serverCode: data.smsCode.code,
            country : this.state.country,
            countryCode : country.dialingCode,
            number : this.state.number,
          });
        }
        else{
          this.setState({loading: false, alreadyRegister: data.message});
        }
      })
    }
  }
  handleChange = prop => event => {
    if(prop === 'country'){
      let country = this.props.Register.countryList.find(m=> m.countryName===event.target.value)
      country && this.props.updateValues({countryCode: '+'+country.dialingCode});
    }
    this.setState({ [prop]: event.target.value});
  };
  selectChange = (name, value) => {
    if(!value) return;
    this.setState({[name]: value });
    let country = this.props.Register.countryList.find(m=> m.countryName===value)
    country && this.props.updateValues({countryCode: '+'+country.dialingCode});
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({alreadyRegister: false });
  };
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onNextClick();
    }
  }
	onlyNumbers(e){
		// eslint-disable-next-line no-useless-escape
		const re = /[0-9\+]+/;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}
  render() {
    const { classes, Register} = this.props;
    console.log(Register, this.state);
    const MyButton = props => <button {...props} />
    return (
      <div className="registerView">
        <div className="text">Enter your contact number</div>
        <div className="text-left">
          <AutoComplete
            className="registerAutoComplete"
            label=""
            name="country"
            placeholder="country"
            defaultValue={true}
            disabled={this.state.loading}
            valueComponent={countryCodeValue} 
            options={Register.countryList.map(m=> ({value: m.countryName, label: m.countryName}))}
            onChange={this.selectChange}
            value={this.state.country}
            error={this.state.errorCountry}
          />
        </div>
        <TextField
          id="mobileNumber"
          placeholder="Mobile Number"
          margin="normal"
          variant="outlined"
          classes={{
            root: classes.cssLabel,
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              focused: classes.inputFocused,
              input: classes.inputWrapper,
            },
            startAdornment: (<InputAdornment position="start" classes={{positionStart: classes.coutnryCodeRoot}}>{Register.countryCode}</InputAdornment>),
          }}
          onKeyPress={(e) => this.onlyNumbers(e)}
          onKeyDown={this._handleKeyDown}
          error={this.state.errorNumber}
          value={this.state.number}
          onChange={this.handleChange('number')}
          disabled={this.state.loading}
        />
        {!this.state.loading ? <div className="stepButton"><Button component={MyButton} onClick={this.onNextClick} variant="contained" color="primary">Continue</Button></div> : <div className="showbox">
            <div className="loader" style={{marginTop: "27px"}}>
              <svg className="circular" viewBox="25 25 50 50">
                <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
              </svg>
            </div>
          </div>
        }
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{root: classes.error}}
          open={this.state.alreadyRegister}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{'aria-describedby': 'message-id',}}
          message={<span id="message-id">{this.state.alreadyRegister}</span>}
        />
      </div>
    );
  }
}
Step1.defaultProps = {
  onNext: ()=>{},
};
Step1.propTypes = {
  classes: PropTypes.object,
  onNext: PropTypes.func,
};
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = {updateValues}

Step1 = connect(mapStateToProps, mapDispatchToProps)(Step1);
export default withStyles(styles)(Step1);