import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PermIdentity from '@material-ui/icons/PermIdentity';
import {styles} from './style';

class Step0 extends Component {
  constructor(props){
    super();
    this.onNextClick = this.onNextClick.bind(this);
    this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.state = {
      firstName : props.Register.firstName,
      lastName : props.Register.lastName,
      email : props.Register.email,
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
    }
  }
  handleChange = prop => event => {
    let errorCheck={}
    if(prop==='firstName'){
      errorCheck={errorFirstName: false}
    }
    else if(prop==='lastName'){
      errorCheck={errorLastName: false}
    }
    else if(prop==='email'){
      errorCheck={errorEmail: false}
    }
    this.setState({ [prop]: event.target.value, ...errorCheck});
  };
  onNextClick(){
    console.log(this.state);
    if(!this.state.firstName){
      this.setState({errorFirstName: true});
    }
    else if(!this.state.lastName){
      this.setState({errorLastName: true});
    }
    else if(!this.state.email || !this.emailRegex.test(this.state.email)){
      this.setState({errorEmail: true});
    }
    else{
      this.props.onNext({
        firstName : this.state.firstName,
        lastName : this.state.lastName,
        email : this.state.email,
      });
    }
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onNextClick();
    }
  }
  render() {
    const { classes} = this.props;
    const MyButton = props => <button {...props} />
    return (
      <div className="registerView">
        <div className="text">Enter your basic information</div>
        <TextField
          id="first_name"
          placeholder="First Name"
          margin="normal"
          variant="outlined"
          onChange={this.handleChange('firstName')}
          classes={{
            root: classes.cssLabel,
          }}
          error={this.state.errorFirstName}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              focused: classes.inputFocused,
              input: classes.inputWrapper,
            },
            startAdornment: (<InputAdornment position="start"><PermIdentity className={classes.icon} /></InputAdornment>),
          }}
        />
        <TextField
          id="last_name"
          placeholder="Last Name"
          margin="normal"
          variant="outlined"
          onChange={this.handleChange('lastName')}
          classes={{
            root: classes.cssLabel,
          }}
          error={this.state.errorLastName}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              focused: classes.inputFocused,
              input: classes.inputWrapper,
            },
            startAdornment: (<InputAdornment position="start"><PermIdentity className={classes.icon} /></InputAdornment>),
          }}
        />
        <TextField
          id="email"
          placeholder="Email"
          margin="normal"
          variant="outlined"
          onKeyDown={this._handleKeyDown}
          onChange={this.handleChange('email')}
          classes={{
            root: classes.cssLabel,
          }}
          error={this.state.errorEmail}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              focused: classes.inputFocused,
              input: classes.inputWrapper,
            },
            startAdornment: (<InputAdornment position="start"><EmailIcon className={classes.icon} /></InputAdornment>),
          }}
        />
        <div className="stepButton"><Button component={MyButton} onClick={this.onNextClick} variant="contained" color="primary">Continue</Button></div>
      </div>
    );
  }
}
Step0.defaultProps = {
  onNext: ()=>{},
};
Step0.propTypes = {
  classes: PropTypes.object,
  onNext: PropTypes.func,
};
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = {}

Step0 = connect(mapStateToProps, mapDispatchToProps)(Step0);
export default withStyles(styles)(Step0);