import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/LockOutlined';
import {styles} from './style';
import { VisitorPost} from '../../store/request';

class Step3 extends Component {
  constructor(props){
    super();
    this.onNextClick = this.onNextClick.bind(this);
    this.state = {
      password: props.Password.password,
      confirmPassword: props.Password.confirmPassword,
      errorPassword : false,
      errorCPassword : false,
      loading: false,
    }
  }
  onNextClick(){
    if(!this.state.password){
      this.setState({errorPassword: true});
    }
    else if(!this.state.confirmPassword || this.state.password!==this.state.confirmPassword){
      this.setState({errorCPassword: true});
    }
    else{
      this.setState({loading: true});
      const {Password} = this.props;
      const requestData = {
        UniqueUserIdentifier: Password.countryCode+Password.number,
        Password: this.state.password,
      };
      VisitorPost('/UpdatePassword', requestData, data=>{
        if(data.code==='001'){
          this.props.history.push('/');
        }
        else{
          this.setState({loading: false});
        }
      })
    }
  }
  handleChange = prop => event => {
    let errorCheck={}
    if(prop==='password'){
      errorCheck={errorPassword: false}
    }
    else if(prop==='confirmPassword'){
      errorCheck={errorCPassword: false}
    }
    this.setState({ [prop]: event.target.value, ...errorCheck});
  };
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onNextClick();
    }
  }
  render() {
    const { classes} = this.props;
    return (
      <div className="passwordView">
        <div className="text">Update Password</div>
        <TextField
          id="password"
          type="password"
          placeholder="Password"
          margin="normal"
          variant="outlined"
          classes={{
            root: classes.cssLabel,
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              focused: classes.inputFocused,
              input: classes.inputWrapper,
            },
            startAdornment: (<InputAdornment position="start"><LockIcon className={classes.icon} /></InputAdornment>),
          }}
          error={this.state.errorPassword}
          value={this.state.password}
          onChange={this.handleChange('password')}
          disabled={this.state.isLoading}
        />
        <TextField
          id="confirmPassword"
          type="password"
          placeholder="Confirm password"
          margin="normal"
          variant="outlined"
          classes={{
            root: classes.cssLabel,
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              focused: classes.inputFocused,
              input: classes.inputWrapper,
            },
            startAdornment: (<InputAdornment position="start"><LockIcon className={classes.icon} /></InputAdornment>),
          }}
          onKeyDown={this._handleKeyDown}
          error={this.state.errorCPassword}
          value={this.state.confirmPassword}
          onChange={this.handleChange('confirmPassword')}
          disabled={this.state.isLoading}
        />
        {!this.state.loading ? <div className="stepButton"><Button onClick={this.onNextClick} variant="contained" color="primary">Update</Button></div> : <div className="showbox">
            <div className="loader" style={{marginTop: "27px"}}>
              <svg className="circular" viewBox="25 25 50 50">
                <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
              </svg>
            </div>
          </div>
        }
      </div>
    );
  }
}
Step3.defaultProps = {
  onNext: ()=>{},
};
Step3.propTypes = {
  classes: PropTypes.object,
  onNext: PropTypes.func,
};
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = {}

Step3 = connect(mapStateToProps, mapDispatchToProps)(Step3);
export default withStyles(styles)(Step3);