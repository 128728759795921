const initialState = {
  user: JSON.parse(localStorage.getItem('_user')),
  productLoading: true,
  productListing: [],
  productSearchText: '',
  productSearchList: [],
  pIncentiveOpen: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'updateValues':
      return {...state, ...action.payload}
    default:
      return state
  }
}