
export const styles = theme => ({
  inputDesign: {
    width: '100%',
  },
  inputUnFocused : {
    borderColor: '#e0e0e0 !important'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  notchedOutline: {},
  inputFocused: {},
  inputWrapper: {
    color: '#253137',
    padding: '14px',
    paddingLeft: '0px',
  },
  selectWrapper: {
    color: '#253137',
    padding: '14px',
    paddingLeft: '0px',
    '&:focus': {
      background: 'transparent',
    },
  },
  cssLabel: {
    width: '100%',
    '&$inputFocused': {
      color: '#f69f21 !important',
    },
  },
  selectRoot:{
    width: '100%',
    textAlign: 'left',
    '&$inputFocused': {
      color: '#f69f21 !important',
    },
  },
  coutnryCodeRoot: {
    color: '#f69f21',
    '&p': {
      color: '#f69f21',
    },
  },
  cssOutlinedInput: {
    '&$inputFocused $notchedOutline': {
      borderColor: '#f69f21',
    },
  },
  labelRoot: {
    color: '#778f9b',
    marginLeft: '-5px',
  },
  formLabelRoot: {},
  checkBoxRoot: {
    color: '#f69f21',
    padding: '0px 15px',
    '&$checked': {
      color: '#f69f21',
    },
  },
  checked: {},
  icon: {color: '#253137'},
  menu: {
    width: 200,
  },
});