import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegisterSteps from '../common/passwordSteps';
import classNames from 'classnames';
import {GetTanent} from '../../store/request';
import './register.scss';
import {register_step, updateValues} from './actions';
import { withStyles } from '@material-ui/core/styles';
import Header from '../common/header';
import Button from '@material-ui/core/Button';
import Step0 from './step0';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import URL from 'config/urls';

const styles = theme => ({
  inputDesign: {
    width: '100%',
  },
  inputUnFocused : {
    borderColor: '#e0e0e0 !important'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  notchedOutline: {},
  inputFocused: {},
  inputWrapper: {
    color: '#253137',
    padding: '14px',
    paddingLeft: '0px',
  },
  cssLabel: {
    width: '100%',
    '&$inputFocused': {
      color: '#f69f21 !important',
    },
  },
  cssOutlinedInput: {
    '&$inputFocused $notchedOutline': {
      borderColor: '#f69f21',
    },
  },
  labelRoot: {
    color: '#778f9b',
    marginLeft: '-5px',
  },
  formLabelRoot: {},
  checkBoxRoot: {
    color: '#f69f21',
    padding: '0px 15px',
    '&$checked': {
      color: '#f69f21',
    },
  },
  checked: {},
  icon: {color: '#253137'}
});

class RegisterPage extends Component {
  constructor(){
    super();
    this.onNextClick = this.onNextClick.bind(this);
    this.steps = [
      {
        text: 'Basic Information'
      },
      {
        text: 'Contact Information'
      },
      {
        text: 'Verify Number'
      },
      {
        text: 'Setup Password'
      },
    ]
  }
  componentDidMount(){
    const tenant = JSON.parse(localStorage.getItem('tennantData')) || {};
    let url = tenant.tennantMapUrl || URL.apiURL;
    console.log(tenant);
    fetch(url + '/GetCountryList')
    .then(data => {
      data.json().then(data => {
        this.props.updateValues({countryList: data});
      });
    });
    GetTanent().then(data => {
      data.json().then(data => {
        localStorage.setItem('tennantData', JSON.stringify(data[0]));
      });
    });
  }
  onNextClick(values={}){
    this.props.updateValues(values);
    this.props.register_step(this.props.Register.step+1);
  }
  render() {
    const {Register} = this.props;
    return (
      <div className="leadsPage">
        <Header isUserLogged={false}>
          <RegisterSteps step={Register.step} steps={this.steps} />
        </Header>
        <section className="container registerArea">
          <div className={classNames("registerCard", {[`step${Register.step}`]: true})}>
            {
              Register.step===3 ? <Step3 history={this.props.history} /> :
              Register.step===2 ? <Step2 onNext={this.onNextClick} /> :
              Register.step===1 ? <Step1 onNext={this.onNextClick} /> :
              <Step0 onNext={this.onNextClick} />
            }
            <div className="signUpView">
              <div className="text">Already have an account?</div>
              <Button variant="contained" color="primary" href="/login" className="signupButton">Sign In Now</Button>
            </div>
          </div>
        </section>
			</div>
    );
  }
}
RegisterPage.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = {
  register_step, updateValues
}

const RegisterPageExport = connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
export default withStyles(styles)(RegisterPageExport);