const initialState = {
  user: JSON.parse(localStorage.getItem('_user')),
  isDetailLoaded: false,
  tab: 0,
  stageList: [],
  detail: {},
  activities: [],
  ownerList: [],
  leadPayDailog: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'updateValues':
      return {...state, ...action.payload}
    case 'updateUser':
      return {...state, user: action.payload}
    default:
      return state
  }
}