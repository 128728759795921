var Application = require('./application');
/*** Mode ***/
//const _isDev = !(process.env.NODE_ENV.replace(/\s/g, '') === 'production');
const _isLive = Application.mode==='production';

/*** API Urls ***/
const _apiURLDev 	= window.location.protocol+'//leadlineprodevapi.azurewebsites.net/api';
const _apiURLLive = window.location.protocol+'//leadlineproliveapi.azurewebsites.net/api';

/*** Tenant Urls ***/
const _tenantURLDev = window.location.protocol+'//oppprodevapi.azurewebsites.net/tenant';
const _tenantURLLive = window.location.protocol+'//oppproliveapi.azurewebsites.net/tenant';

/*** Opporunity Urls ***/
const _oppFrontURLDev = window.location.protocol+'//oppprodev.azurewebsites.net';
const _oppFrontURLLive = window.location.protocol+'//opportunity.exceeders.com';

/*** API Urls ***/
var _oppApiURLDev = window.location.protocol+'//oppprodevapi.azurewebsites.net/api';
var _oppApiURLLive = window.location.protocol+'//exceedersopportunity-api.azurewebsites.net/apiv3';

/*** Opporunity Urls ***/
const _leadlineDev = window.location.protocol+'//leadlineprodev.azurewebsites.net';
const _leadlineLive = window.location.protocol+'//leadlineprolive.azurewebsites.net';


console.log(Application.mode==='development', Application)

module.exports = {
	webURL: !_isLive ? _leadlineDev : _leadlineLive,
	officeWebURL: _leadlineLive,
	apiURL: !_isLive ? _apiURLDev : _apiURLLive,
	tenantURL: !_isLive ? _tenantURLDev : _tenantURLLive,
	productImage : !_isLive ? _oppApiURLDev+"/downloadfileattachmentexternal" : _oppApiURLLive+"/downloadfileattachmentexternal",
	opportunityURL:  !_isLive ? _oppFrontURLDev : _oppFrontURLLive
};