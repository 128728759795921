import { version } from '../../package.json';

const initialState = {
  version: version,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_VERSION':
      return {...state, version: action.payload}
    default:
      return state
  }
}




// firstName: 'asdf',
// lastName: 'asdf',
// email: 'asdf@asd.com',
// country: '92',
// countryCode: '+92',
// number: '3339603042',
// serverCode: '3836',
// code: '3836',
// password: '123456',
// confirmPassword: '123456',
// codeResend: true,
// countryList: []
