import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused  ? 'rgba(246, 159, 33, 0.5)' : state.backgroundColor,
    color: state.isFocused  ? '#fff' : '#333'
  }),
}
const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>{children || props.selectProps.label}</components.SingleValue>
  );
};

class AutoComplete extends Component {
  state={
    value: {},
  }
  componentDidMount(){
    if(this.props.defaultValue){
      this.setState({value: this.props.value ? this.props.value : this.props.options[0]});
      this.selectChange(this.props.value ? this.props.value : this.props.options[0]);
    }
  }
  selectChange = (value) => {
    if(!value) return;
    console.log(this.props.name ? this.props.name : this.props.label, value.value);
    this.setState({value});
    this.props.onChange(this.props.name ? this.props.name : this.props.label, value.value);
  }
  UNSAFE_componentWillReceiveProps(props){
    let findOption = this.props.options.find(o => o.value===props.value);
    this.setState({value: findOption || {}});
  }
	render() {
    let {noMessage} = this.props;
    let component={
      SingleValue: SingleValue
    }
    if(typeof this.props.valueComponent !== 'object'){
      component = {SingleValue: this.props.valueComponent}
    }
    component.NoOptionsMessage = (noProps) => {
      return (
        <components.NoOptionsMessage {...noProps}>{noMessage ? noMessage : noProps.children}</components.NoOptionsMessage>
      );
    }
		return (
			<div className="db vt" style={{marginTop: "8px", marginBottom: '3px'}}>
        {this.state.value && Object.entries(this.state.value).length>0 && <label className="autoCompleteLabel" htmlFor="selectId">{this.props.label}</label>}
        <Select
          inputId="selectId"
          styles={Object.assign({}, this.props.customStyles, customStyles)}
          className={this.props.error ? this.props.className+" hasError" : this.props.className}
          classNamePrefix="autoComplete"
          value={this.state.value}
          onChange={this.selectChange}
          options={this.props.options}
          components={component}
          label={this.props.label}
          isDisabled={this.props.disabled}
          placeholder={this.props.placeholder}
          menuPlacement="auto"
        />
			</div>
		)
	}
}
AutoComplete.defaultProps = {
  label: 'label',
  name: '',
  value: '',
  defaultValue: true,
  disabled: false,
  isClearable: false,
  error: false,
  valueComponent: {},
  className : "autoComplete",
  customStyles: {},
  onChange: ()=>{},
  noMessage: '',
  placeholder: '',
}
AutoComplete.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
}
export default AutoComplete;