import { combineReducers } from 'redux';
import Package from './reducer';
import Register from '../components/register/reducer';
import Password from '../components/passwordReset/reducer';
import Lead from '../components/lead/listing/reducer';
import LeadDetail from '../components/lead/detail/reducer';
import User from '../components/user/reducer';
import Product from '../components/product/listing/reducer';
import ProductDetail from '../components/product/detail/reducer';
import Monitor from '../components/monitor/reducer';

const appReducer = combineReducers({Package ,Register, Password, Lead, LeadDetail, User, Product, ProductDetail, Monitor})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action)
}

export default rootReducer;