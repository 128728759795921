import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import './login.scss';
import { withStyles } from '@material-ui/core/styles';
import Header from '../common/header';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/LockOutlined';
import {styles} from './style';
import {GetTanentByUser, token, GetData} from '../../store/request';
import Snackbar from '@material-ui/core/Snackbar';

class LoginPage extends Component {
  constructor(props){
    super();
    this.onLoginButton = this.onLoginButton.bind(this);
    this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.state = {
      email: '',
      password: '',
      // email: 'adnan@mailinator.com',
      // password: '123456',
      rememberMe: true,
      errorEmail : false,
      errorPassword : false,
      loginError: false,
      loading: false,
    }
  }
  componentDidMount(){
    localStorage.clear();
  }
  onLoginButton(){
    if(!this.state.email){
      this.setState({errorEmail: true});
    }
    else if(!this.state.password){
      this.setState({errorPassword: true});
    }
    else{
      this.setState({loading: true});
      let email = this.state.email;
      if(this.emailRegex.test(email)){
        this.setState({loading: false, loginError: 'Please provide a valid number'});
        return true;
      }
      if(!this.emailRegex.test(email) && !email.includes("_@")){
        if(email.startsWith("+")){
          email = email.replace(/^\++/, '');
        }
        if(email.startsWith("0")){
          email = parseInt(email, 10);
        }
        email = '+'+email;
      }
      console.log(email);
      GetTanentByUser(encodeURIComponent(email)).then(data => {
        data.json().then(data => {
          if(data.length > 0){
            let tennant = data[0];
            localStorage.setItem('tennantData', JSON.stringify(tennant));
            token('/token', "grant_type=password&username="+encodeURIComponent(email)+"&password="+this.state.password+"&authenticationmode=custom", token => {
              localStorage.setItem('_lg', true);
              localStorage.setItem('_access', token.access_token);
              GetData('/GetUserDetails').then(userData => {
                localStorage.setItem('_user', JSON.stringify(userData.result));
                this.props.history.push('/');
              });
            }, err=>{
              this.setState({loading: false, loginError: 'You have entered an invalid username or password'});
            })
          }
          else{
            this.setState({loading: false, loginError: 'You have entered an invalid username or password'});
          }
        });
      });
    }
  }
  handleChange = prop => event => {
    if(prop==='rememberMe'){
      this.setState({ [prop]: event.target.checked});
      return;
    }
    this.setState({ [prop]: event.target.value});
  };
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ loginError: false });
  };
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onLoginButton();
    }
  }
	onlyNumbers(e){
		// eslint-disable-next-line no-useless-escape
		const re = /[0-9\+]+/;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}
  render() {
    const { classes, Package} = this.props;
    console.log(this.props, Package);
    return (
      <div className="leadsPage">
        <Header isUserLogged={false}></Header>
        <section className="container loginArea">
          <div className="loginCard">
            <div className="loginView">
              <div className="text">Welcome to LeadLine</div>
              <TextField
                id="email"
                placeholder="Mobile Number"
                margin="normal"
                type="text"
                variant="outlined"
                classes={{root: classes.cssLabel}}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    focused: classes.inputFocused,
                    input: classes.inputWrapper,
                  },
                  startAdornment: (<InputAdornment position="start"><EmailIcon className={classes.icon} /></InputAdornment>),
                }}
                onKeyPress={(e) => this.onlyNumbers(e)}
                onChange={this.handleChange('email')}
                value={this.state.email}
                error={this.state.errorEmail}
                disabled={this.state.loading}
              />
              <TextField
                id="password"
                placeholder="Password"
                margin="normal"
                type="password"
                variant="outlined"
                classes={{root: classes.cssLabel}}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    focused: classes.inputFocused,
                    input: classes.inputWrapper,
                  },
                  startAdornment: (<InputAdornment position="start"><LockIcon className={classes.icon} /></InputAdornment>),
                }}
                onKeyDown={this._handleKeyDown}
                onChange={this.handleChange('password')}
                value={this.state.password}
                error={this.state.errorPassword}
                disabled={this.state.loading}
              />
              <div className="rememberArea">
                <a href="/password" className="forgetLink">Forgot password?</a>
              </div>
              {!this.state.loading ? <div className="loginButton"><Button onClick={this.onLoginButton} variant="contained" color="primary">Sign In</Button></div> : <div className="showbox">
                  <div className="loader" style={{marginTop: "16px"}}>
                    <svg className="circular" viewBox="25 25 50 50">
                      <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                    </svg>
                  </div>
                </div>
              }
            </div>
            <div className="signUpView">
              <div className="text">Don’t have an account?</div>
              <Button href="/register" variant="contained" color="primary" className="signupButton">Sign Up Now</Button>
            </div>
          </div>
          <div className="verionNumber">Version: {Package.version}</div>
        </section>
        <Snackbar
          anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
          classes={{root: classes.error}}
          open={Boolean(this.state.loginError)}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{'aria-describedby': 'message-id'}}
          message={<span id="message-id">{this.state.loginError || 'You have entered an invalid username or password'}</span>}
        />
			</div>
    );
  }
}
LoginPage.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = { }

LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default withStyles(styles)(LoginPage);