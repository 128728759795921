import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Steps extends Component {
	render() {
		return (
			<div className={classnames("steps")}>
        {
          this.props.steps.map((step, s)=>{
            return(
              <div key={s} className={classnames("step", {'isActive': this.props.step===s, 'completed': this.props.step>s})}>
                <div className="icon">
                  <i className="material-icons">done</i>
                  <span className="number">{parseInt(s)+1}</span>
                </div>
                <div className="text">{step.text}</div>
              </div>
            );
          })
        }
			</div>
		)
	}
}
Steps.defaultProps = {
  step: 0,
  steps: [],
}
Steps.propTypes = {
	step: PropTypes.number,
	steps: PropTypes.array,
}
export default Steps;