import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null};
  }

  componentDidCatch(err, info) {
    // Display fallback UI
    this.setState({ hasError: true, error: err});
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    console.log(err, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="container page404 errorPage">
          <div className="noListContainer">
            <div className="noList">
              <div className="nl-icon"><img src={require('images/404.svg')} alt="" /></div>
              <div className="nl-header">Sorry, something went wrong!</div>
              <div className="nl-des" style={{ whiteSpace: 'pre-wrap' }}>{this.state.error.toString()}</div>
              <a href="/" className="nl-button">Go to Home Page</a>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children;
  }
}
export default ErrorBoundary;