const initialState = {
  user: JSON.parse(localStorage.getItem('_user')),
  topTotal: [],
  loading: true,
  stageList: [],
  open: 0,
  singleList: [],
  isAddOpen: false,
  leadSearchText: '',
  searchList: [],

  isFilterOpen: false,
  filterType: '',
  savedFilter: JSON.parse(localStorage.getItem('_lf')) || {
    "isDateFilterApplied": 0,
    "startDate": '',
    "endDate": '',
    "isLeadOwnerFilterApplied": 0,
    "leadOwnerFilterList": [],
    "isProductFilterApplied": 0,
    "productFilterList": [],
    "isProductOwnerFilterApplied": 0,
    "productOwnerFilterList": [],
    "isLastMovedDateFilterApplied": 0,
    "lastMovedStartDate": '',
    "lastMovedEndDate": '',
    "isClosedDateFilterApplied": 0,
    "closedStartDate": '',
    "closedEndDate": '',
    "activityTypeFilterApplied": 0,
    "activityType": "0",
  },
  leadOwnerList: [],
  productFilterList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'updateValues':
      return {...state, ...action.payload}
    default:
      return state
  }
}