export const register_step = (step) => dispatch => {
  dispatch({
    type: 'UPDATE_STEP',
    payload: step
  })
}
export const updateValues = (values) => dispatch => {
  dispatch({
    type: 'updateValues',
    payload: values
  })
}