import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import {showLoading} from 'components/common/commonFunctions';
import {GetData} from 'store/request';

class OptionMenu extends Component {
  state={
    anchorEl:null,
    loading: true,
    integration: false,
  }
  componentDidMount(){
    GetData('/IsUserThirdPartyIntegrationDone?UserId='+this.props.userId).then(e=>{
      this.setState({loading: false, integration: e.result});
    });
  }
  handleClick= event=>{
    this.setState({anchorEl: event.currentTarget});
  }
  handleClose= event=>{
    this.setState({anchorEl: null});
  }
  handleItemClick= event=>{
    this.setState({anchorEl: null});
		let target = event.target, id=0, type='';
		while (target && target.parentNode) {
			if (target.tagName && target.tagName.toLowerCase() === 'li') {
				type = target.getAttribute('data-type');
				id = parseInt(target.getAttribute('data-id'));
				break;
			}
			target = target.parentNode;
		}
		id>0 && this.props.onItemClick(type, id);
  }
	render() {
    const open = Boolean(this.state.anchorEl);
		return (
			<div style={{marginTop: '-6px', marginBottom: '-6px'}}>
      <IconButton aria-label="More" aria-owns={open ? 'headerOption' : undefined} aria-haspopup="true" onClick={this.handleClick}
      >
        {
          this.state.loading ? showLoading({small: true}) :
          <MoreVertIcon />
        }
      </IconButton>
      <Menu
        id="headerOption"
        anchorEl={this.state.anchorEl}
        open={!this.state.loading && open}
        onClose={this.handleClose}
      >
        {
          this.state.integration ?
          <MenuItem key={1} data-type="removeOffice" data-id={this.props.userId} onClick={this.handleItemClick}>Disconnect Outlook</MenuItem> :
          <MenuItem key={2} data-type="office365" data-id={this.props.userId} onClick={this.handleItemClick}>Connect Outlook</MenuItem>
        }
      </Menu>
    </div>
		)
	}
}
OptionMenu.defaultProps = {
	onItemClick: ()=>{},
}
OptionMenu.propTypes = {
	onItemClick: PropTypes.func,
}
export default OptionMenu;

