import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import {history} from '../routes/history';

const UserLogOut = store => next => action => {
  if(action.type === 'USER_LOGOUT') {
    // after a successful login, update the token in the API;
    if(action.payload === "Unauthorized"){
      history.push('/logout');
    }
  }
  // continue processing this action
  return next(action);
}
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    applyMiddleware(thunk, UserLogOut)
  );
}