import URL from 'config/urls';
import Apps from 'config/application';
import store from './index';

export const PostData = (url = "", data = {}) => {
	let Store = store();
	return new Promise(function (resolve, reject) {
		const tenant = JSON.parse(localStorage.getItem('tennantData')) || {};
		const accessToken = localStorage.getItem('_access') || "";
		var http = new XMLHttpRequest();
		http.open('POST', tenant.tennantMapUrl + url, true);
		http.setRequestHeader('Content-Type', "application/json");
		if (tenant.customHeader) {
			http.setRequestHeader('CustomHeader', tenant.customHeader);
		}
		if (accessToken) {
			http.setRequestHeader('authorization', 'bearer ' + accessToken);
		}
		http.onreadystatechange = function () {
			if (http.readyState === 4 && http.status === 200) {
				let res = JSON.parse(http.response);
				res.code === '001' ? resolve(res) : reject(res)
			}
			else if (http.readyState === 4 && http.status === 500) {
				reject(http);
			}
			else if (http.readyState === 4 && http.status === 401) {
				Store.dispatch({
					type: 'USER_LOGOUT',
					payload: http.statusText
				});
			}
		}
		http.onerror = function (e) {
			reject(http);
		};
		http.send(JSON.stringify(data));
	});
}
export const GetData = (url = ``) => {
	let Store = store();
	return new Promise(function (resolve, reject) {
		const tenant = JSON.parse(localStorage.getItem('tennantData')) || {};
		const accessToken = localStorage.getItem('_access') || "";
		var http = new XMLHttpRequest();
		http.open('GET', tenant.tennantMapUrl + url, true);
		http.setRequestHeader('Content-Type', "application/json");
		if (tenant.customHeader) {
			http.setRequestHeader('CustomHeader', tenant.customHeader);
		}
		if (accessToken) {
			http.setRequestHeader('authorization', 'bearer ' + accessToken);
		}
		http.onreadystatechange = function () {
			if (http.readyState === 4 && http.status === 200) {
				resolve(JSON.parse(http.response));
			}
			else if (http.status === 500) {
				reject({
					statusText: http.statusText
				});
			}
			else if (http.readyState === 4 && http.status === 401) {
				Store.dispatch({
					type: 'USER_LOGOUT',
					payload: http.statusText
				});
			}
		}
		http.onerror = function () {
			reject({
				status: this.status,
				statusText: http.statusText
			});
		};
		http.send();

	});
}

export const FileGet = (url = ``) => {
	return new Promise(function (resolve, reject) {
		const tenant = JSON.parse(localStorage.getItem('tennantData')) || {};
		const accessToken = localStorage.getItem('_access') || "";
		var http = new XMLHttpRequest();
		http.responseType = 'blob';
		http.open('GET', tenant.tennantMapUrl + url, true);
		if (tenant.customHeader) {
			http.setRequestHeader('CustomHeader', tenant.customHeader);
		}
		if (accessToken) {
			http.setRequestHeader('Authorization', 'bearer ' + accessToken);
		}
		http.onreadystatechange = function () {
			if (http.readyState === 4 && http.status === 200) {
				resolve(http.response);
			}
			else if (http.status === 500) {
				reject({
					statusText: http.statusText
				});
			}
		}
		http.onerror = function () {
			reject({
				status: this.status,
				statusText: http.statusText
			});
		};
		http.send();

	});
}
export const PostUnstructure = (url = ``, data = {}) => {
	return new Promise(function (resolve, reject) {
		const tenant = JSON.parse(localStorage.getItem('tennantData')) || {};
		const accessToken = localStorage.getItem('_access') || "";
		var http = new XMLHttpRequest();
		http.open('POST', tenant.tennantMapUrl + url, true);
		http.setRequestHeader('Content-Type', "application/json");
		if (tenant.customHeader) {
			http.setRequestHeader('CustomHeader', tenant.customHeader);
		}
		if (accessToken) {
			http.setRequestHeader('authorization', 'bearer ' + accessToken);
		}
		http.onreadystatechange = function () {
			if (http.readyState === 4 && http.status === 200) {
				resolve(JSON.parse(http.response));
			}
			else if (http.status === 500) {
				reject(http);
			}
		}
		http.onerror = function () {
			reject(http);
		};
		http.send(JSON.stringify(data));
	});
}
export const GetTanent = (url = ``, data = {}) => {
	// Default options are marked with *
	return fetch(URL.tenantURL + '/GetTennantUrlByClientId?Clientcode=' + (Apps.mode === 'development' ? 'leadlinedev' : 'LeadLineLive'), {
		method: "GET", // *GET, POST, PUT, DELETE, etc.
	})
}
export const GetTanentByUser = (user = '') => {
	// Default options are marked with *
	return fetch(URL.tenantURL + '/GetTennantUrl?userUniqueIdentifier=' + user)
}
export const token = (url = ``, data = "", callback = () => { }, errorCallback = () => { }) => {
	const tenant = JSON.parse(localStorage.getItem('tennantData')) || {}
	var http = new XMLHttpRequest();
	http.open('POST', tenant.tennantMapUrl + url, true);
	http.setRequestHeader('Content-Type', "application/x-www-form-urlencoded");
	http.setRequestHeader('CustomHeader', tenant.customHeader);
	http.onreadystatechange = function () {
		if (http.readyState === 4 && http.status === 200) {
			callback(JSON.parse(http.responseText));
		}
		else if (http.readyState === 4 && http.status !== 200) {
			errorCallback(http);
		}
	}
	http.onerror = function () {
		errorCallback(http)
	}
	http.send(data);
}
export const VisitorPost = (url = ``, data = {}, callback = () => { }) => {
	const tenant = JSON.parse(localStorage.getItem('tennantData')) || {}
	var http = new XMLHttpRequest();
	http.open('POST', tenant.tennantMapUrl + url, true);
	http.setRequestHeader('Content-Type', "application/json");
	http.setRequestHeader('CustomHeader', tenant.customHeader);
	http.onreadystatechange = function () {
		if (http.readyState === 4 && http.status === 200) {
			callback(JSON.parse(http.responseText));
		}
	}
	http.send(JSON.stringify(data));
}