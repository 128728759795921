const initialState = {
  step: 0,
  country: {value: "United Arab Emirates", label: "United Arab Emirates"},
  countryCode: '+971',
  number: '',
  serverCode: '',
  code: '',
  password: '',
  confirmPassword: '',
  codeResend: true,
  countryList: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_STEP':
      return {...state, step: action.payload}
    case 'updateValues':
      return {...state, ...action.payload}
    default:
      return state
  }
}




// country: '92',
// countryCode: '+92',
// number: '3339603042',
// serverCode: '3836',
// code: '3836',
// password: '123456',
// confirmPassword: '123456',
// codeResend: true,
// countryList: []