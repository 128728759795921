const initialState = {
  user: JSON.parse(localStorage.getItem('_user')),
  monitorLoading: true,
  requests: [],
  delegate: 0,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'updateValues':
      return {...state, ...action.payload}
    case 'UpdateUser':
      return {...state, user: action.payload}
    case 'UpdateRequests':
      return {...state, requests: action.payload}
    default:
      return state
  }
}