import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {deepGet, showLoading} from 'components/common/commonFunctions';
import {PostData} from 'store/request';
import {withStyles} from '@material-ui/core/styles';
import {styles} from './style';
import Snackbar from '@material-ui/core/Snackbar';

class Office365 extends Component {
  constructor(props){
    super();
    this.state = {
      code: '',
      error: false,
      loading: false,
    }
  }
  componentDidMount(){
    let {user, history} = this.props;
    let params = new URLSearchParams(deepGet(this.props, ['location', 'search'], ''));
    const code = params.get("code");
    console.log(this.props, code);
    if(code){
      setTimeout(e=>{
        PostData('/GetOffice365WebToken', {
          "code": code,
        }).then(response => {
          console.log(response, user);
          if(!user){
            user = JSON.parse(localStorage.getItem('_user'))
          }
          if(deepGet(response, ['result', 'access_token'], null) && deepGet(response, ['result', 'refresh_token'], null)){
            PostData('/AddThirdPartyIntegration', {
              "userId": deepGet(user, ['userId'], ''),
              "userEmail": deepGet(user, ['email'], ''),
              "accessToken": deepGet(response, ['result', 'access_token'], ''),
              "refreshToken": deepGet(response, ['result', 'refresh_token'], ''),
              "extraInformation": "",
              "createdOn": new Date().toISBString(),
              "updatedOn": new Date().toISBString(),
            }).then(per => {
              this.snackBarHandler('You are successfully integrated.');
            }).catch(error=>{
              this.snackBarHandler('Oops. Something went wrong');
            });
          }
          else{
            this.snackBarHandler('Oops. Something went wrong');
          }
          console.log(response, user);
        }).catch(error=>{
          history.push('/');
        });
      }, 2000);
    }
    else{
      history.push('/');
    }
  }
  snackBarHandler= e=>{
    let {history} = this.props;
    this.setState({error: e});
    setTimeout(a=>{
      history.push('/');
    }, 3000);
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({error: false });
  };
  render() {
    const {classes} = this.props;
    return (
      <div className="db vt" style={{height: '100%'}}>
        <div className="centered">{showLoading()}</div>
        <Snackbar
          anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
          classes={{root: classes.error}}
          open={this.state.error}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{'aria-describedby': 'message-id'}}
          message={<span id="message-id">{ this.state.error || 'Oops. Something went wrong'}</span>}
        />
      </div>
    );
  }
}
Office365.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({...state})
const mapDispatchToProps = {}
Office365 = connect(mapStateToProps, mapDispatchToProps)(Office365);
export default withStyles(styles)(Office365);