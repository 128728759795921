import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FlagSMS from '@material-ui/icons/SmsOutlined';
import {styles} from './style';
import { VisitorPost } from '../../store/request';
import {updateValues} from './actions';
import Snackbar from '@material-ui/core/Snackbar';

class Step1 extends Component {
  constructor(props){
    super();
    this.onNextClick = this.onNextClick.bind(this);
    this.onResend = this.onResend.bind(this);
    this.state = {
      code: props.Password.code,
      serverCode: props.Password.serverCode,
      errorCode : false,
      isResend: false,
      snackMessage: null,
    }
  }
  onNextClick(){
    // if(!this.state.code || this.state.code!==this.state.serverCode){
    //   this.setState({errorCode: true});
    // }
    // else{
    //   this.props.onNext();
    // }
    
    this.setState({loading: true});
    let plusSign = '';
    if(!this.props.Register.countryCode.includes('+')){
      plusSign = '+';
    }
    VisitorPost('/VerifySMSCode ', {"PhoneNumber": plusSign + this.props.Password.countryCode+this.props.Password.number, Code: this.state.code}, data=>{
      if(data){
        this.props.onNext();
      }
      else{
        this.setState({snackMessage: "Provided code was incorrect", errorCode: true});
      }
    }, error=> {
      this.setState({snackMessage: error.message || "Error occured while checking code", errorCode: true});
    });
  }
  onResend(){
    if(this.state.isResend)return false;
    this.setState({isResend: true});
    VisitorPost('/GenerateSMSCodeForUpdate', {"PhoneNumber": this.props.Password.countryCode+this.props.Password.number}, data=>{
      if(data.smsCode.code){
        this.setState({serverCode: data.smsCode.code});
        this.props.updateValues({
          serverCode: data.smsCode.code,
        });
        this.setState({snackMessage: "Code Sent"});
        setTimeout(f=> {this.setState({isResend: false})}, 30000)
      }
      else{
        this.setState({snackMessage: data.message});
      }
    }, error=> {
      this.setState({snackMessage: error.message || "Error occured while sending code"});
    })
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value});
  }
  handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({snackMessage: null });
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onNextClick();
    }
  }
  render() {
    const { classes} = this.props;
    return (
      <div className="passwordView">
        <div className="text">Verify your number</div>
        <TextField
          id="smsode"
          placeholder="Code"
          margin="normal"
          variant="outlined"
          classes={{
            root: classes.cssLabel,
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              focused: classes.inputFocused,
              input: classes.inputWrapper,
            },
            startAdornment: (<InputAdornment position="start" classes={{positionStart: classes.coutnryCodeRoot}}><FlagSMS className={classes.icon} /></InputAdornment>),
          }}
          onKeyDown={this._handleKeyDown}
          error={this.state.errorCode}
          value={this.state.code}
          onChange={this.handleChange('code')}
        />
        <div className="rememberArea">
          <div onClick={this.onResend} className={classNames("resendLink", {'disabled': this.state.isResend})}>Resend code again?</div>
        </div>
        <div className="stepButton"><Button onClick={this.onNextClick} variant="contained" color="primary">Continue</Button></div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{root: classes.error}}
          open={Boolean(this.state.snackMessage)}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
          ContentProps={{'aria-describedby': 'message-id',}}
          message={<span id="message-id">{this.state.snackMessage || "Code Sent"}</span>}
        />
      </div>
    );
  }
}
Step1.defaultProps = {
  onNext: ()=>{},
};
Step1.propTypes = {
  classes: PropTypes.object,
  onNext: PropTypes.func,
};
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = {updateValues}

Step1 = connect(mapStateToProps, mapDispatchToProps)(Step1);
export default withStyles(styles)(Step1);