import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Store from './store';
import {Route} from './routes';
// eslint-disable-next-line no-unused-vars
import CF from 'config/extensions';

class App extends Component {
  render() {
    return (
      <Provider store={Store()}>
        <Route />
      </Provider>
    );
  }
}

export default App;
