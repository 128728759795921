/* eslint-disable no-extend-native */
function pad(number) {
	if (number < 10) {
		return '0' + number;
	}
	return number;
}

Date.prototype.isSameDay = function(d2) {
	return this.getFullYear() === d2.getFullYear() &&
  this.getMonth() === d2.getMonth() &&
  this.getDate() === d2.getDate();
};

Date.prototype.resetTime = function() {
	var date = new Date(this);
	date.setHours(0, 0, 0, 0);
	return date;
};
Date.prototype.maximizeTime = function() {
	return new Date(this).setHours(23).setMinutes(59).setSeconds(59)
};
Date.prototype.toISBString = function() {
	return this.getFullYear() +
		'-' + pad(this.getMonth() + 1) +
		'-' + pad(this.getDate()) +
		'T' + pad(this.getHours()) +
		':' + pad(this.getMinutes()) +
		':' + pad(this.getSeconds()) +
		'Z';
};
Date.prototype.toISTString = function() {
	let timeZone = this.getTimezoneOffset();
	const uper = Math.abs(timeZone/60);
	const lower = timeZone % 60;
	return this.getFullYear() +
		'-' + pad(this.getMonth() + 1) +
		'-' + pad(this.getDate()) +
		'T' + pad(this.getHours()) +
		':' + pad(this.getMinutes()) +
		':' + pad(this.getSeconds()) +
		(timeZone <= 0 ? '+': '-') + pad(Math.floor(uper)) +
		':' + pad(((lower/60) * 100).toFixed(0));
};
Date.prototype.minusOneDay = function() {
	this.setDate(this.getDate() - 1);
	return this;
};
Date.prototype.plusOneDay = function() {
	this.setDate(this.getDate() + 1);
	return this;
};
Date.prototype.UTCToLocal = function() {
	var newDate = new Date(this.getTime() - this.getTimezoneOffset()*60*1000);
    return newDate;
};
Date.prototype.formatTime = function() {
	console.log(this.getHours());
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct","Nov", "Dec"];

  var day = this.getDate() < 10 ? "0" + this.getDate() : this.getDate();
  var monthIndex = this.getMonth();
  var year = this.getFullYear();

	let hours = this.getHours() > 12 ? this.getHours() - 12 : this.getHours();
	let am_pm = this.getHours() >= 12 ? "PM" : "AM";
	let minutes = this.getMinutes() < 10 ? "0" + this.getMinutes() : this.getMinutes();
	return monthNames[monthIndex] + ' ' + day + ', ' + year +' '+ hours + ":" + minutes + " " + am_pm;
};

Date.prototype.formatTimeSeconds = function() {
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct","Nov", "Dec"];
  var day = this.getDate() < 10 ? "0" + this.getDate() : this.getDate();
  var monthIndex = this.getMonth();
	let minutes = this.getMinutes() < 10 ? "0" + this.getMinutes() : this.getMinutes();
	let seconds = this.getSeconds() < 10 ? "0" + this.getSeconds() : this.getSeconds();
	return monthNames[monthIndex] +' '+ day +', '+ this.getFullYear() +' '+ this.getHours() +":"+ minutes+":"+seconds;
};