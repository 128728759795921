const initialState = {
  user: JSON.parse(localStorage.getItem('_user')),
  userList: [],
  userDetail: 0,
  userPermission: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'updateUserState':
      return {...state, ...action.payload}
    default:
      return state
  }
}