import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegisterSteps from '../common/passwordSteps';
import classNames from 'classnames';
import {GetTanent} from '../../store/request';
import './password.scss';
import {updateStep, updateValues} from './actions';
import { withStyles } from '@material-ui/core/styles';
import Header from '../common/header';
import Button from '@material-ui/core/Button';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import {styles} from './style';
import {showLoading} from 'components/common/commonFunctions';


class PasswordPage extends Component {
  constructor(){
    super();
    this.onNextClick = this.onNextClick.bind(this);
    this.steps = [
      {
        text: 'Contact Information'
      },
      {
        text: 'Verify Number'
      },
      {
        text: 'Update Password'
      },
    ]
  }
  componentDidMount(){
    fetch('https://leadlineprodevapi.azurewebsites.net/api/GetCountryList')
    .then(data => {
      data.json().then(data => {
        this.props.updateValues({countryList: data});
      });
    });
    GetTanent().then(data => {
      data.json().then(data => {
        localStorage.setItem('tennantData', JSON.stringify(data[0]));
      });
    });
  }
  onNextClick(values={}){
    this.props.updateValues(values);
    this.props.updateStep(this.props.Password.step+1);
  }
  render() {
    const {Password} = this.props;
    return (
      <div className="leadsPage">
        <Header isUserLogged={false}>
          <RegisterSteps step={Password.step} steps={this.steps} />
        </Header>
        <section className="container passwordArea">
          {
            Password.countryList.length<=0 ?
            <div className="centered">{showLoading()}</div> : 
            <div className={classNames("passwordCard", {[`step${Password.step}`]: true})}>
              {
                Password.step===2 ? <Step3 onNext={this.onNextClick} history={this.props.history} /> :
                Password.step===1 ? <Step2 onNext={this.onNextClick} /> :
                <Step1 onNext={this.onNextClick} />
              }
              <div className="signUpView">
                <div className="text">Already have an account?</div>
                <Button variant="contained" color="primary" href="/login" className="signupButton">Sign In Now</Button>
              </div>
            </div>
          }
        </section>
			</div>
    );
  }
}
PasswordPage.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({...state})
const mapDispatchToProps = {updateStep, updateValues}
PasswordPage = connect(mapStateToProps, mapDispatchToProps)(PasswordPage);
export default withStyles(styles)(PasswordPage);