import Loadable from 'react-loadable';
import React from 'react';
import Loading from '../common/small/loading';
import ErrorBoundary from '../common/small/errorBoundary';

const LoadableComponent = Loadable({
	loader: () => import('./index'),
	loading() {
    return <Loading />
  },render(loaded, props) {
    let LoadComponent = loaded.default;
    return <LoadComponent {...props}/>;
  }
});

export default class ActivitiesMain extends React.Component {
  render() {
    return <ErrorBoundary><LoadableComponent {...this.props}/></ErrorBoundary>;
  }
}