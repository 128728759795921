import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {showLoading} from 'components/common/commonFunctions';
import {PostData, GetTanent} from 'store/request';

class Verify extends Component {
  constructor(props){
    super();
    this.state = {
      code: '',
      error: false,
      verify: false,
      loading: true,
    }
  }
  componentDidMount(){
    let {match} = this.props;
    let type=match.params.type;
    let code=match.params.code;
    if(type==='email' && code){
      GetTanent().then(data => {
        data.json().then(data => {
          localStorage.setItem('tennantData', JSON.stringify(data[0]));
          PostData('/VerifyUserEmail?code='+code).then(response => {
            this.setState({loading: false, verify: true});
          }).catch(error=>{
            this.setState({loading: false, verify: false});
          });
        });
      })
      .catch(error=>{
        this.setState({loading: false, verify: false});
      });
    }
    else{
      this.setState({loading: false, verify: false});
    }
  }
  render() {
    return (
      <div className="db vt" style={{height: '100%'}}>
        {
          this.state.loading ? <div className="centered">{showLoading()}</div> : 
          this.state.verify ? 
          <div className="emailVerify">
            <div className="message">Email has been verified!</div>
            <a href="/login" className="login">Click here to login</a>
          </div> : 
          <div className="emailVerify">
            <div className="message">Code you entered is wrong or already used</div>
            <a href="/login" className="login">Click here to login</a>
          </div>
        }
      </div>
    );
  }
}
Verify.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({...state})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Verify);
